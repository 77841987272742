<template>
  <div :style="toggle==1?'background-color: #f2f3f5;height: 97.6%;':''">
    <div class="mains" v-if="loading" v-loading="loading" element-loading-spinner="el-icon-loading"></div>
    <div v-else>
      <div class="man-title" style="display:flex;justify-content:space-between;align-items:center;">
        <div>
          <span>数据采集</span>
        </div>
        <div class="right-toggle">
          <span v-show="toggle==0" @click="toggleList" class="r-icon iconfont icon-gonggepailie"></span>
          <span v-show="toggle==1" @click="toggleCard" class="r-icon iconfont icon-liebiao1"></span>
        </div>
      </div>
      <!-- <div class="search">
        <el-form :inline="true" :model="formInline" class="demo-form-inline">
          <el-form-item label="水厂">
            <el-select size="small" v-model="formInline.waterworksName" clearable placeholder="请选择水厂">
              <el-option
                v-for="item in waterworks"
                :label="item"
                :value="item"
                :key="item"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button size="small" type="primary" @click="onSubmit">查询</el-button>
          </el-form-item>
        </el-form>
      </div> -->
      <!-- 列表 -->
      <div v-show="toggle==0">
        <div class="table-tree">
          <div class="tree" v-loading="treeLoading" element-loading-spinner="el-icon-loading">
            <div class="tree-title"><i class="el-icon-share" style="margin-right:10px;"></i>设备品牌分类</div>
            <div v-if="supplierData.length>0">
              <div class="tree-park" v-for="(item,index) in supplierData" :key="index">
                <div class="park-title" :class="current==item.equipmentBrandCode?'bright':''" @click.prevent="brandToggle(item.equipmentBrandCode)">
                  <span class="class-icon iconfont icon-hezi" :style="current==item.equipmentBrandCode?'color:#FFF;':'color:#3498db;'"></span>
                  <span>{{item.equipmentBrand}}</span>
                </div>
              </div>
            </div>
            <div v-else style="width: 100%;text-align: center;line-height: 60px;font-size: 14px;color: #909399;">暂无数据</div>
          </div>
          <div class="table">
            <el-table :data="tableData" style="width: 100%" v-loading="tableLoading" element-loading-spinner="el-icon-loading">
              <el-table-column label="序号" type="index" width="50"></el-table-column>
              <el-table-column prop="waterworks" label="水厂">
                <template slot-scope="scope">
                  {{ scope.row.waterworks?scope.row.waterworks:'-' }}
                </template>
              </el-table-column>
              <el-table-column prop="position" label="泵房编号">
                <template slot-scope="scope">
                  {{ scope.row.position?scope.row.position:'-' }}
                </template>
              </el-table-column>
              <el-table-column prop="equipmentName" label="设备名称">
                <template slot-scope="scope">
                  {{scope.row.equipmentName?scope.row.equipmentName:'-'}}
                </template>
              </el-table-column>
              <el-table-column prop="eid" label="设备号"></el-table-column>
              <!-- <el-table-column prop="flowInstantaneous" label="瞬时流量（m³/h）" width="140">
                <template slot-scope="scope">
                  {{ scope.row.content.flowInstantaneous?scope.row.content.flowInstantaneous:0 }}
                </template>
              </el-table-column>
              <el-table-column prop="fluidVelocity" label="瞬时流速（m/s）" width="140">
                <template slot-scope="scope">
                  {{ scope.row.content.fluidVelocity?scope.row.content.fluidVelocity:0 }}
                </template>
              </el-table-column>
              <el-table-column prop="positiveCumulativeFlow" label="正累积流量（m³）" width="140">
                <template slot-scope="scope">
                  {{ scope.row.content.positiveCumulativeFlow?scope.row.content.positiveCumulativeFlow:0 }}
                </template>
              </el-table-column>
              <el-table-column prop="negativeCumulativeFlow" label="负累积流量（m³）" width="140">
                <template slot-scope="scope">
                  {{ scope.row.content.negativeCumulativeFlow?scope.row.content.negativeCumulativeFlow:0 }}
                </template>
              </el-table-column> -->
              <el-table-column prop="cardNumber" label="物联网卡号">
                <template slot-scope="scope">
                  {{ scope.row.cardNumber?scope.row.cardNumber:'-' }}
                </template>
              </el-table-column>
              <!-- <el-table-column prop="reportTime" label="上报时间">
                <template slot-scope="scope">
                  {{ scope.row.reportTime ? dayjs(scope.row.reportTime).format('YYYY-MM-DD HH:mm:ss'):'-' }}
                </template>
              </el-table-column> -->
              <el-table-column prop="remarks" label="备注">
                <template slot-scope="scope">
                  {{ scope.row.remarks?scope.row.remarks:'-' }}
                </template>
              </el-table-column>
              <el-table-column label="操作" width="180">
                <template slot-scope="scope">
                  <el-dropdown placement="bottom-start" trigger="click">
                    <span @click.stop="">
                      <span class="el-dropdown-link">
                        操作<i class="el-icon-arrow-down"></i>
                      </span>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item><div @click="record(scope.row)">历史记录</div></el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </template>
              </el-table-column>
            </el-table>
            <page v-if="gatewayTotal!==0" :size="gatewaySize" :page="gatewayPage" :total="gatewayTotal" v-on:sizeChange="gatewaySizeChange" v-on:pageChange="gatewayPageChange"></page>
          </div>
        </div>
      </div>
      <!-- 卡片 -->
      <div v-show="toggle==1">
        <div v-if="tableData.length>0">
          <div class="sub-equipment-model">
            <div class="equipment-item" :style="toggle==1?'background:#FFF;':''" v-for="(item, index) in tableData" :key="index">
              <div class="equipment-header">
                <div class="h-name">
                  <i class="iconfont icon-wangguanshebei" style="font-weight: normal;"></i>    
                  <span style="margin-left:5px;">
                    {{item.waterworks?item.waterworks + item.position:'-'}}
                  </span>
                </div>
                <div class="h-time">
                  <el-button size="mini" plain type="primary" @click="record(item)">历史记录</el-button>
                </div>
              </div>
              <div class="equipment-middle">
                <div class="middle-params">
                  <div>设备名称</div>
                  <div>{{item.equipmentName?item.equipmentName:'-'}}</div>
                </div>
                <div class="middle-params">
                  <div>设备号</div>
                  <div>{{item.eid?item.eid:'-'}}</div>
                </div>
                <!-- <div class="middle-params">
                  <div>瞬时流量（m³/h）</div>
                  <div>{{item.content.flowInstantaneous?item.content.flowInstantaneous:0}}</div>
                </div>
                <div class="middle-params">
                  <div>瞬时流速（m/s）</div>
                  <div>{{item.content.fluidVelocity?item.content.fluidVelocity:0}}</div>
                </div>
                <div class="middle-params">
                  <div>正累积流量（m³）</div>
                  <div>{{item.content.positiveCumulativeFlow?item.content.positiveCumulativeFlow:0}}</div>
                </div>
                <div class="middle-params">
                  <div>负累积流量（m³）</div>
                  <div>{{item.content.negativeCumulativeFlow?item.content.negativeCumulativeFlow:0}}</div>
                </div> -->
                <div class="middle-params">
                  <div>物联网卡号</div>
                  <div>{{ item.cardNumber ? item.cardNumber:'-' }}</div>
                </div>
                <!-- <div class="middle-params">
                  <div>上报时间</div>
                  <div>{{ item.reportTime ? dayjs(item.reportTime).format('YYYY-MM-DD HH:mm:ss'):'-' }}</div>
                </div> -->
              </div>
            </div>
          </div>
          <div class="block">
            <el-pagination
              @size-change="sizeChange"
              @current-change="pageChange"
              :current-page="page"
              :page-size="size"
              :page-sizes="[50]"
              background
              :layout="layout"
              :total="total"
            ></el-pagination>
            <!-- :pager-count="count" -->
          </div>
        </div>
        <div v-else style="width: 100%;text-align: center;line-height: 60px;font-size: 14px;color: #909399;">暂无数据</div>
      </div>
    </div>
  </div>
</template>

<script>
import page from '@/components/page.vue'
import dayjs from 'dayjs';
export default {
  components: {
    page
  },
  data() {
    return {
      toggle: 0,
      loading: false,
      treeLoading: false,
      tableLoading: false,

      gatewaySize: 50,
      gatewayPage: 1,
      gatewayTotal: 0,

      size: 50,
      page: 1,
      total: 0,

      formInline: {},
      waterworks: [],
      tableData: [],

      supplierData: [],
      current: null,
      equipmentBrandCode: null,
      equipmentName: null,
    };
  },
  computed: {
    layout() {
      let str = "total, sizes, prev, pager, next, jumper";
      this.isSizes?str="sizes,"+str:"";
      return str;
    }
  },
  methods: {
    dayjs,
    edit(row) {
      this.$router.push({ name: 'deviceListEdit', params: {
        row: row,
        bumen: this.bumen,
        leixin: this.leixin,
        yuangong: this.yuangong
      }})
    },
    details(row) {
      this.$router.push({ name: 'deviceListDetail', params: {row: row}})
    },
    dlt(row) {
      this.$confirm('确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$ajax.post('deviceDelete', {
          id: row.id
        }).then(res => {
          this.loadListData()
        })
      })
    },
    onSubmit() {
      if(this.toggle==0) {
        this.loadListData(this.gatewayPage,this.gatewaySize,this.equipmentBrandCode)
      }else {
        this.loadListData(this.page,this.size,this.equipmentBrandCode)
      }
    },
    load_waterworks() {
      this.$ajax.get("waterWorksQuery").then((res) => {
        this.waterworks = res.data;
      });
    },
    toggleList() {
      this.toggle = 1
      sessionStorage.setItem("toggle",this.toggle)
      this.loadListData(this.page,this.size,this.equipmentBrandCode)
    },
    toggleCard() {
      this.toggle = 0
      sessionStorage.setItem("toggle",this.toggle)
      this.loadListData(this.gatewayPage,this.gatewaySize,this.equipmentBrandCode)
    },

    // 记录
    record(row) {
      this.$router.push({ name: 'iotReportFirstRecord', params: {row: row}})
    },

    // 切换品牌
    brandToggle(equipmentBrandCode) {
      this.current = equipmentBrandCode
      this.equipmentBrandCode = equipmentBrandCode
      sessionStorage.setItem('equipmentBrandCode',equipmentBrandCode)
      this.loadListData(this.gatewayPage,this.gatewaySize,equipmentBrandCode)
    },

    // 获取品牌
    loadBrand() {
      this.treeLoading = true
      this.$ajax.post('equipmentGroupQuery',{
        deviceTypeCodes: ['GatewaySub']
      }).then(res => {
        if(res.data.length==0) {
          this.treeLoading = false
        }
        this.supplierData = res.data
        if(sessionStorage.getItem('equipmentBrandCode')){
          this.current = sessionStorage.getItem('equipmentBrandCode')
          this.equipmentBrandCode = sessionStorage.getItem('equipmentBrandCode')
          console.log(this.equipmentBrandCode)
          this.loadListData(this.gatewayPage,this.gatewaySize,this.equipmentBrandCode)
        }else{
          this.current = this.supplierData[0].equipmentBrandCode
          this.equipmentBrandCode = this.supplierData[0].equipmentBrandCode
          console.log(this.supplierData[0].equipmentBrandCode)
          this.loadListData(this.gatewayPage,this.gatewaySize,this.equipmentBrandCode)
        }
        this.treeLoading = false
      }).catch(err=>{
        this.treeLoading = false
      })
    },

    // 获取设备
    loadListData(pageNum,pageSize,equipmentBrandCode) {
      this.tableLoading = true
      this.$ajax.post('equipmentByEcode',{
        pageNum: pageNum,
        pageSize: pageSize,
        equipmentBrandCode: equipmentBrandCode,
      }).then(res => {
        this.tableData = res.data
        if(this.toggle==0) {
          this.gatewayTotal = res.total
        }else {
          this.total = res.total
        }
        this.tableLoading = false
      }).catch(err=>{
        this.tableLoading = false
      })
    },
    // 网关
    gatewayPageChange(val) {
      this.gatewayPage = val
      sessionStorage.setItem('currentPage', val)
      this.loadListData(this.gatewayPage,this.gatewaySize,this.equipmentBrandCode)
    },
    gatewaySizeChange(val) {
      this.gatewaySize = val
      sessionStorage.setItem('currentSize', val)
      this.loadListData(this.gatewayPage,this.gatewaySize,this.equipmentBrandCode)
    },
    // 网关
    pageChange(val) {
      this.page = val
      sessionStorage.setItem('currentCardPage', val)
      this.loadListData(this.page,this.size,this.equipmentBrandCode)
    },
    sizeChange(val) {
      this.size = val
      sessionStorage.setItem('currentCardSize', val)
      this.loadListData(this.page,this.size,this.equipmentBrandCode)
    },
    getLastPage() {
      this.gatewayPage = Number(sessionStorage.getItem('currentPage')) || 1
      this.page = Number(sessionStorage.getItem('currentCardPage')) || 1
    },
    getSize() {
      this.gatewaySize = Number(sessionStorage.getItem('currentSize')) || 50
      this.size = Number(sessionStorage.getItem('currentCardSize')) || 50
    },
  },
  mounted() {
    if(sessionStorage.getItem("toggle")) {
      this.toggle = parseInt(sessionStorage.getItem("toggle"))
    }
    this.load_waterworks()
    this.getLastPage()
    this.getSize()
    this.loadBrand()
  },
  destroyed() {
    
  }
};
</script>

<style lang="less" scoped>
.mains {
  padding: 400px 0;
  height: 100%;
}
/deep/.el-loading-spinner .el-icon-loading{
  font-size: 50px;
}
.block /deep/{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 10px;
  padding: 0 20px;
  .el-pager li {
    margin: 0 5px;
    border-radius: 2px;
  }
}
.table-tree {
  display: flex;
  height: ~'calc(100% - 56px)';
  .tree {
    width: 300px;
    border-right: 4px solid #f6f7fb;
    margin-right: 20px;
  }
  .table {
    flex: 1;
  }
  .operate {
    &:hover {
      color: #409EFF;
    }
  }
  .custom-tree-node {
    font-size: 14px;
  }
}
.el-dropdown-dis {
  color: #ccc;
  cursor: not-allowed;
}
.r-icon {
  font-size: 26px;
  color: #333;
  cursor:pointer;
  width:40px;
  text-align:center;
  border-radius:6px;
  padding: 6px;
  transition: all .3s ease;
  font-weight: normal;
}
.r-icon:hover{
  background:#efefef;
}

.sub-equipment-model {
    padding: 0 0px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.equipment-item {
    width: 361px;
    // height: 220px;
    // background: #F0F3F8;//F0F3F8
    // box-shadow: 0px 0px 20px rgba(211, 211, 211, .5);
    // border: 1px solid #ebeef5;
    border-radius: 10px;
    margin-right: 15px;
    margin-bottom: 15px;
    padding: 15px 20px;
    display: flex;
    flex-direction: column;
}
.equipment-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    font-size: 17px;
    font-weight: bold;
    border-bottom: 1px solid #ebeef5;
    padding-bottom: 10px;
    color: #333;
    .h-time {
        color: #aaa;
        font-size: 14px;
    }
}
.equipment-middle {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
} 
.middle-params {
    width: 42%;
    padding: 10px;
    margin: 3px;
    color: #202020;
    font-size: 14px;
    border-radius: 10px;
    transition: all .3s ease;
}
.middle-params:hover {
    background: #F3F7FB;
}
.middle-params div:nth-child(1) {
    color: #999;
    font-size: 12px;
    margin-bottom: 5px;
}
.tree-park {
  font-size: 14px;
  cursor: pointer;
}
.park-title {
  line-height: 35px;
  padding-left: 20px;
}
.tree-classify {
  line-height: 35px;
}
.classify-title {
  padding-left: 50px;
}
.class-icon {
  display: inline-block;
  margin-right: 8px;
}
.bright {
  background: #409EFF;
  color: #FFF;
}
.dialog-footer {
  display: flex;
  justify-content: flex-end;
}
.cancel-btn {
  margin-left: 20px;
}
.import-park {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.park-required {
  color: red;
  margin-right: 3px;
}
</style>